<template>
  <div class="details" ref="main">
    <div class="top">
      <div class="topline"></div>
      <div class="arrear-order">
        <div class="arrear-title">
          <div>
            历史欠费
            <span style="color: #333333; font-weight: 100">
              （<i style="color: #2370ff; font-weight: 600"> ￥{{ (arrearPayTotal * 1) | rounding }} </i> /
              {{ arrearList.length }}笔）
            </span>
          </div>
        </div>
        <van-checkbox-group v-model="selectList" ref="checkboxGroup" @change="allOrderChange">
          <div class="arrear-box" v-for="(park, index) in arrearList" :key="index">
            <div v-show="index >= 3 && editNum == 2 ? false : true">
              <div class="arrear-msg">
                <div class="msg-box">
                  <div>车场名称</div>
                  <div class="value">{{ park.parkingName }}</div>
                </div>
                <div class="msg-box">
                  <div>驶入时间</div>
                  <div class="value">{{ park.enterTime }}</div>
                </div>
                <div class="msg-box">
                  <div>驶出时间</div>
                  <div class="value">{{ park.exitTime }}</div>
                </div>
                <div class="msg-box">
                  <div>欠费金额</div>
                  <div class="value" style="color: #2370ff">￥{{ (park.arrear * 1) | rounding }}</div>
                </div>
              </div>
              <div class="check-coupon">
                <van-checkbox :name="park" @click="handleOrderClick(park.order)"></van-checkbox>
                <van-cell
                  class="coupon"
                  title="优惠券"
                  is-link
                  :value="
                    park.arrear == park.oramount
                      ? park.hasCoupon
                        ? '有可用券'
                        : '无可用券'
                      : '优惠 -￥' + (park.oramount - park.arrear)
                  "
                  @click="getCouponList(park.order, park.oramount, park.id, park.enterTime)"
                >
                  <template #title>
                    <span class="custom-title">本单待付：</span>
                    <span style="color: #2370ff">￥{{ (park.arrear * 1) | rounding }}</span>
                  </template>
                </van-cell>
              </div>
              <van-divider
                :hairline="false"
                dashed
                :style="{ borderColor: '#E5E5E5' }"
                style="margin: 0; padding: 15px 0"
              />
            </div>
          </div>
        </van-checkbox-group>
        <div class="showall" v-if="arrearList.length >= 3 && editNum == 2 ? true : false" @click="editNum = 3">
          展示全部<van-icon name="arrow-down" />
        </div>
      </div>
      <div class="foot-line"></div>
    </div>
    <!-- 底部支付 -->
    <div class="tab-bar">
      <van-checkbox style="width: 70px" v-model="checkAll" @click="handleCheckAllChange" v-if="checkedCount">
        全选
      </van-checkbox>
      <div>
        <div>
          <span>您需支付：</span>
          <span style="color: #2370ff"> ￥{{ (arrearTotal * 1) | rounding }} </span>
        </div>
        <div style="color: #eb5757; font-size: 13px">共优惠￥{{ (deduction * 1) | rounding }}</div>
      </div>
      <van-button
        :style="{
          'background-color': '#2370FF',
        }"
        @click="payhandel"
        v-preventReClick="3000"
      >
        支付
      </van-button>
    </div>
    <!-- 优惠券框 -->
    <van-popup
      v-model="couponshow"
      closeable
      round
      position="bottom"
      :style="{ height: activityList.length ? '80%' : allCouponList.length ? '70%' : '30%' }"
      class="coupon-popup"
      @click-close-icon="couponCancel"
    >
      <div class="getcoupon-list" v-if="activityList.length">
        <div class="getcoupon-title">
          <img src="../../../../assets/getcoupon-title.png" />
        </div>
        <div class="getcoupon-view" v-for="(item, index) in activityList" :key="index">
          <div class="left">
            <div class="getcoupon-view-top">{{ item.name }}</div>
            <div class="getcoupon-view-middle">
              {{
                item.type == 1
                  ? "授权手机号注册会员，体验更多功能"
                  : item.type == 2
                  ? "关注企业公众号，停车有位更优惠"
                  : item.type == 3
                  ? "停车订单在线支付即可领取"
                  : item.type == 4
                  ? "回馈新老用户，免费领券，限量供应"
                  : "免费领取优惠券"
              }}
            </div>
            <div class="getcoupon-view-bottom">
              <div class="coupon-count" v-for="(list, index) in item.bzEquitiesGoodsList" :key="index">
                <div class="coupon-count-left">{{ list.typeToString }}</div>
                <div class="coupon-count-right">{{ list.valueToString }}</div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="getbtn" @click="getActiveityCoupon(item.type, item.id)">
              <div>
                {{
                  item.type == 1
                    ? "注册"
                    : item.type == 2
                    ? "关注"
                    : item.type == 3
                    ? "支付"
                    : item.type == 4
                    ? "免费"
                    : "立刻"
                }}
              </div>
              <div>领取</div>
            </div>
          </div>
        </div>
      </div>
      <div class="gap" v-if="activityList.length"></div>
      <div class="coupon-list">
        <div v-if="allCouponList.length">
          <div class="coupon-title">可用优惠券</div>
          <van-divider style="margin: 0; padding: 15px 0" :hairline="false" />
          <div class="checkmsg">
            您已选择{{ checkCouponList.length }}张优惠券，可抵扣<span style="color: #ff7627">
              {{ "￥" + deduction }}
            </span>
          </div>
          <van-radio-group v-model="couponCheck" @change="changeRad">
            <div v-for="(item, index) in allCouponList" :key="index">
              <div class="coupon-view" v-show="item.isShow" :class="{ active: couponClickIndex == index }">
                <div class="expired-label" v-if="item.beAlmostExpired">
                  <img src="../../../../assets/coupon-expired-label.png" alt="" />
                </div>
                <div class="semi-circle-top" :class="{ 'active-topround': couponClickIndex == index }"></div>
                <div class="semi-circle-bottom" :class="{ 'active-bottomround': couponClickIndex == index }"></div>
                <div class="coupon-type">{{ couponTypeList[item.equitiesType] }}</div>
                <div class="coupon-msg">
                  <div class="coupon-favour-left" v-if="item.equitiesType !== 2">
                    <div style="font-size: 30px">
                      {{ item.value }}
                      <span style="font-size: 15px">
                        {{
                          item.equitiesType == 1
                            ? "元"
                            : item.equitiesType == 2
                            ? "免单"
                            : item.equitiesType == 3
                            ? "元"
                            : "折"
                        }}
                      </span>
                    </div>
                    <div>{{ item.conditionToString }}</div>
                  </div>
                  <div class="coupon-favour-left" v-else>
                    <div style="font-size: 18px; font-weight: 550">免单体验</div>
                    <div>({{ item.valueToString }})</div>
                    <div>{{ item.conditionToString }}</div>
                  </div>
                  <div class="coupon-favour-right">
                    <div class="favour-dsc">{{ item.equitiesName }}</div>
                    <div class="select">
                      <van-radio :name="item.id" checked-color="#FFA763" @click="radClick(index)"></van-radio>
                    </div>
                    <div class="favour-date">
                      <div>
                        有效期至：
                        {{ item.validityTime }}
                      </div>
                      <!-- <div>详情<van-icon name="arrow" /></div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-radio-group>
          <van-divider>暂无更多</van-divider>
        </div>
        <van-empty v-else description="暂无可用优惠券" />
      </div>
      <div class="coupon-define" v-if="allCouponList.length">
        <van-button round block class="cancel" @click="couponCancel">取消</van-button>
        <van-button round block class="submit" @click="couponsumbit">确定</van-button>
      </div>
    </van-popup>

    <SigninCouponAlert :sendshow="signinShow" @destroyed="alertDestroyed(1)"></SigninCouponAlert>
    <GetCouponAlert :sendshow="getCouponShow" @destroyed="alertDestroyed(4)"></GetCouponAlert>
    <GetSuccess :sendshow="getSuccessShow" @destroyed="alertDestroyed(23)"></GetSuccess>
  </div>
</template>

<script>
import _ from "lodash";
import * as math from "mathjs";
import { Dialog } from "vant";
import SigninCouponAlert from "../../../../component/signinCouponAlert";
import GetCouponAlert from "../../../../component/getCouponAlert";
import GetSuccess from "../../../../component/getSuccess";

export default {
  name: "Details",
  components: {
    [Dialog.Component.name]: Dialog.Component,
    SigninCouponAlert,
    GetCouponAlert,
    GetSuccess,
  },
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
      showedit: false,
      arrearList: [],
      appId: localStorage.getItem("appId"),
      itemId: null,
      plateNo: localStorage.getItem("plateNo"),
      openId: null,
      parkName: null,
      recordNo: null,
      type: localStorage.getItem("type"),
      recordList: [],
      // 欠费订单全选
      selectList: [],
      // 全选
      checkAll: true,
      // 是否展示全部
      editNum: null,
      // 选中的优惠券
      checkCouponList: [],
      // 勾选订单的总长
      checkedCount: 0,
      // 优惠券列表
      allCouponList: [],
      // 选中的优惠券下标
      couponClickIndex: -1,
      // 选中的优惠券
      couponCheck: null,
      // 是否展示优惠券
      couponshow: false,
      // 选中的优惠券订单id
      checkOrderId: null,
      // 选中的订单id
      checkparkId: null,
      couponTypeList: {
        1: "现金券",
        2: "免单券",
        3: "满减券",
        4: "折扣券",
      },
      // 活动列表
      activityList: [],
      // 注册优惠券是否展示
      signinShow: false,
      // 领券是否展示
      getCouponShow: false,
      // 领取成功是否展示
      getSuccessShow: false,
      // 优惠券选中变化
      couponRaidChange: false,
      // 欠费数组
      recordNoes: [],
    };
  },
  watch: {
    // signinShow: {
    //   handler(val) {
    //     val ? (this.$refs.main.style.filter = `blur(2px)`) : (this.$refs.main.style.filter = null);
    //   },
    // },
    // getCouponShow: {
    //   handler(val) {
    //     val ? (this.$refs.main.style.filter = `blur(2px)`) : (this.$refs.main.style.filter = null);
    //   },
    // },
  },
  mounted() {
    this.parkName = this.$route.query.parkName;
    this.itemId = this.$route.query.itemId;
    this.recordNo = this.$route.query.recordNo;
    this.getArrearList();
  },
  computed: {
    arrearTotal() {
      // let sum = 0;
      // this.arrearList.forEach((item) => {
      //   sum += item.arrear;
      // });
      return this.selectList.reduce((accumulator, currentValue) => {
        const valueToAdd = currentValue.arrear;
        return math.add(math.bignumber(accumulator), math.bignumber(valueToAdd));
      }, 0);
    },
    arrearPayTotal() {
      // let sum = 0;
      // this.arrearList.forEach((item) => {
      //   sum += item.arrear;
      // });
      return this.arrearList.reduce((accumulator, currentValue) => {
        const valueToAdd = currentValue.arrear;
        return math.add(math.bignumber(accumulator), math.bignumber(valueToAdd));
      }, 0);
    },
    // 抵扣金额
    deduction() {
      return this.checkCouponList.length
        ? this.checkCouponList.reduce((accumulator, currentValue) => {
            const valueToAdd = currentValue.deduction;
            return math.add(math.bignumber(accumulator), math.bignumber(valueToAdd));
          }, 0)
        : 0;
    },
  },
  methods: {
    // 优惠券框选择变化
    changeRad() {
      this.couponRaidChange = true;
    },
    // 优惠券选择
    radClick(index) {
      this.couponClickIndex = index;
      if (!this.couponRaidChange) {
        this.couponCheck = "";
        this.couponClickIndex = -1;
      }
      this.couponRaidChange = false;
    },
    // 优惠券选择取消
    couponCancel() {
      this.couponshow = false;
      this.couponCheck = "";
      this.checkOrderId = null;
      this.checkparkId = null;
    },
    // 领取弹框关闭感知
    alertDestroyed(type) {
      this.getArrearList();
      if (type == 1) {
        this.signinShow = false;
      }
      if (type == 4) {
        this.getCouponShow = false;
      }
    },
    // 活动券领取
    getActiveityCoupon(type, id) {
      this.couponshow = false;
      if (type == 1) {
        this.signinShow = true;
        this.getCouponShow = false;
        this.getSuccessShow = false;
      }
      if (type == 2) {
        let appId = localStorage.getItem("appId");
        let openId = localStorage.getItem("openId");
        let alipayuserId = localStorage.getItem("alipayuserId");
        this.$axios.post(`${this.domain}/api/opercenter/disposeFocusOfficial`, {
          appId,
          openId: openId ? openId : alipayuserId,
          id,
        });
        this.$axios
          .post(`${this.domain}/api/opercenter/queryUninBase64`, {
            appId,
          })
          .then((res) => {
            let url = `https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=${res.data.data}&scene=110#wechat_redirect`;
            location.href = url;
          });
      }
      if (type == 3) {
        this.$toast("支付订单即可领取到优惠券");
      }
      if (type == 4) {
        let sign = this.activityList.filter((item) => item.type === 1);
        let follow = this.activityList.filter((item) => item.type === 2);
        if (sign.length > 0) {
          this.$toast("请先领取用户注册领取优惠券活动");
        } else if (follow.length > 0) {
          this.$toast("请先领取用户关注公众号领取优惠券活动");
        } else {
          this.signinShow = false;
          this.getCouponShow = true;
          this.getSuccessShow = false;
        }
      }
    },
    // 优惠券选择确定
    couponsumbit() {
      if (this.couponCheck !== "") {
        let afterFee = this.allCouponList.filter((item) => item.id === this.couponCheck)[0].afterFee;
        let deduction = this.allCouponList.filter((item) => item.id === this.couponCheck)[0].deduction;
        let ticketMark = this.allCouponList.filter((item) => item.id === this.couponCheck)[0].code;
        if (this.checkCouponList.length) {
          let exists = false;
          for (const item of this.checkCouponList) {
            if (item.id * 1 === this.couponCheck * 1) {
              exists = true;
              break;
            }
          }
          if (!exists) {
            // 在添加新对象之前检查是否存在相同 orderId 的对象
            const existingIndex = this.checkCouponList.findIndex((item) => item.orderId === this.checkOrderId);
            if (existingIndex !== -1) {
              // 如果存在相同 orderId 的对象，先删除旧的对象
              this.checkCouponList.splice(existingIndex, 1);
            }
            this.checkCouponList.push({
              id: this.couponCheck,
              orderId: this.checkOrderId,
              parkId: this.checkparkId,
              afterFee,
              deduction,
              ticketMark,
            });
          }
        } else {
          this.checkCouponList.push({
            id: this.couponCheck,
            orderId: this.checkOrderId,
            parkId: this.checkparkId,
            afterFee,
            deduction,
            ticketMark,
          });
        }
        // 将金额还原为初始金额，以防订单重新选择了其他优惠券导致金额不对
        this.selectList.forEach((item) => {
          item.arrear = item.oramount;
        });
        this.arrearList.forEach((item) => {
          item.arrear = item.oramount;
        });
        // 将使用优惠券之后需要支付的价格修改便于计算相加
        this.selectList.forEach((item) => {
          const commonObj = this.checkCouponList.find((list) => list.orderId === item.order);
          if (commonObj) {
            // 修改使用了优惠券之后的值
            item.arrear = commonObj.afterFee;
          }
        });
      }
      this.couponshow = false;
    },
    // 全选
    handleCheckAllChange(e) {
      if (!this.checkAll) {
        this.checkCouponList = [];
        this.arrearList.forEach((item) => {
          item.arrear = item.oramount;
        });
      }
      this.checkAll ? this.$refs.checkboxGroup.toggleAll(true) : this.$refs.checkboxGroup.toggleAll(false);
    },
    // 优惠券查询
    getCouponList(outTradeOrder, payAmount, id, parkingTime) {
      this.couponClickIndex = -1;
      // 判断订单是否已经勾选
      const isSelect = this.selectList.findIndex((item) => item.id === id);
      if (isSelect !== -1) {
        this.couponCheck = "";
        this.checkOrderId = outTradeOrder;
        this.$toast.loading({
          duration: 0,
          message: "加载中...",
          forbidClick: true,
          loadingType: "spinner",
        });
        let openId = localStorage.getItem("openId");
        let alipayuserId = localStorage.getItem("alipayuserId");
        let appId = localStorage.getItem("appId");
        let itemId = localStorage.getItem("itemId");
        this.$axios
          .post(`${this.domain}/api/opercenter/queryH5EquitiesGoodsByUserOfPlate`, {
            appId,
            itemId,
            openId: openId ? openId : null,
            payAmount,
            outTradeOrder,
            parkingTime,
          })
          .then((res) => {
            if (res.data.data.length) {
              for (const addedItem of res.data.data) {
                const matchingItem = this.checkCouponList.find((item) => item.id === addedItem.id);
                if (matchingItem) {
                  // 如果找到匹配的项
                  if (matchingItem.orderId === outTradeOrder) {
                    // 如果orderId也相同，将 isShow 设置为 true
                    addedItem.isShow = true;
                    this.couponCheck = matchingItem.id;
                  } else {
                    // 如果orderId不相同，将 isShow 设置为 false
                    addedItem.isShow = false;
                  }
                } else {
                  // 如果没找到匹配的项，将 isShow 设置为 true
                  addedItem.isShow = true;
                }
              }
              this.allCouponList = res.data.data;
            }
          })
          .then((res) => {
            // 活动列表
            this.$axios
              .post(`${this.domain}/api/opercenter/queryBzActivityOfParkPay`, {
                appId,
                openId: openId ? openId : null,
              })
              .then((res) => {
                if (res.data.data) {
                  this.activityList = res.data.data;
                  // 活动领取反馈
                  this.$axios.post(`${this.domain}/api/opercenter/queryActivityOfH5FeedBack`, {
                    appId,
                    openId: openId ? openId : null,
                  });
                }
                this.$toast.clear();
                this.couponshow = true;
              });
          })
          .catch(() => {
            this.$toast.clear();
            this.$toast.fail("加载失败");
          });
      } else {
        this.$toast("请先勾选当前订单");
      }
    },
    // 点击勾选或取消勾选订单
    handleOrderClick(order) {
      let newList = this.selectList.filter((item) => item.order === order);
      if (newList.length == 0) {
        // 如果有优惠券取消勾选后删除掉
        const existingIndex = this.checkCouponList.findIndex((item) => item.orderId === order);
        if (existingIndex !== -1) {
          // 如果存在相同 orderId 的对象，先删除旧的对象
          this.checkCouponList.splice(existingIndex, 1);
        }
        // 查看欠费订单是否取消勾选后存在相同的订单，将金额初始化
        this.arrearList.forEach((item) => {
          if (item.order === order) {
            item.amount = item.oramount;
          }
        });
      }
    },
    // 监听勾选订单改变
    allOrderChange(val) {
      if (val.length == this.checkedCount) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    },
    // 获取欠费详情
    async getArrearList() {
      let { appId, plateNo, itemId } = this;
      let openId = localStorage.getItem("openId");
      let alipayuserId = localStorage.getItem("alipayuserId");
      this.recordList = [];
      await this.$axios
        .post(`${this.domain}/api/parklotPay/queryArrearListForH5Detail`, {
          appId,
          plateNo,
          itemId,
          openId: openId ? openId : alipayuserId,
        })
        .then((res) => {
          this.arrearList = res.data.data;
          // 判断是否展示三条欠费
          res.data.data.length > 3 ? (this.editNum = 2) : (this.editNum = 3);
          this.checkedCount = res.data.data.length;
          res.data.data.forEach((item, index) => {
            this.arrearList[index]["oramount"] = item.arrear;
            this.recordList.push(item.order);
          });
        });
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.checkboxGroup.toggleAll(true);
        }, 0);
      });
    },
    payhandel: _.debounce(function () {
      let { type, itemId, appId } = this;
      let openId = localStorage.getItem("openId");
      let userId = localStorage.getItem("alipayuserId");
      let channelId = localStorage.getItem("channelId");
      this.$axios.get(`${this.domain}/api/jinhua/select/${appId}/${itemId}`).then((res) => {
        if (res.data.data !== null) {
          this.loading = true;
          this.$axios
            .post(`${this.domain}/api/jinhua/parkPay`, {
              appId,
              itemId,
              clientIp: "127.0.0.1",
              total_fee: paymentOrderDate.dataItems[0].paidFee,
              recordNo: paymentOrderDate.dataItems[0].recordNo,
              total: paymentOrderDate.dataItems[0].payableFee,
              reduce: paymentOrderDate.dataItems[0].discountFee,
              useTime: paymentOrderDate.dataItems[0].useTime,
              type,
              channelId: channelId == "undefined" ? null : channelId,
              method: openId ? 1 : 2,
            })
            .then((res) => {
              this.loading = false;
              window.location.href = res.data.data;
            });
        } else {
          if (openId) {
            this.wxSettlement();
          }
          if (userId) {
            this.aliSettlement();
          }
        }
      });
    }, 100),
    // 支付宝结算
    aliSettlement() {
      let { recordList, appId, itemId, plateNo, checkCouponList } = this;
      let userId = localStorage.getItem("alipayuserId");
      let arrearList = [];
      let resultArray = [];
      let diffArray = [];
      if (this.selectList.length) {
        resultArray = checkCouponList.filter((item1) => this.selectList.some((item2) => item2.id === item1.parkId));
        diffArray = this.selectList.filter((item) => !resultArray.some((item1) => item1.parkId === item.id));
      }
      let useCouponList = [];
      let unUseCouponList = [];
      if (resultArray.length) {
        useCouponList = resultArray.map((item) => ({
          parkRecordId: item.parkId ? item.parkId + "" : item.id ? item.id + "" : null,
          code: item.parkId ? item.parkId : 0,
          deduction: item.parkId ? item.deduction : 0,
        }));
      }
      if (diffArray.length) {
        unUseCouponList = diffArray.map((item) => ({
          parkRecordId: item.parkId ? item.parkId + "" : item.id ? item.id + "" : null,
          code: 0,
          deduction: 0,
        }));
      }
      arrearList = useCouponList.concat(unUseCouponList);
      this.$axios
        .post(`${this.domain}/api/parklotPay/arrearPayOfParkLot`, {
          itemId,
          appId,
          plateNo,
          payMethod: "alipay",
          openId: userId,
          nos: recordList,
          arrearList: arrearList.length ? arrearList : null,
          payFee: this.arrearTotal + "",
        })
        .then((res) => {
          this.recordNoes = res.data.data.recordNoes;
          if (res.data.code === 200) {
            this.tradePay(`${res.data.data.trade_no}`);
            this.paid = true;
          } else if (res.code == 404) {
            Dialog.alert({
              message: `${res.data.msg}`,
            }).then(() => {
              // on close
            });
          } else {
            Dialog.alert({
              message: `${res.data.msg}`,
            }).then(() => {
              // on close
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    // 微信结算
    wxSettlement() {
      let { recordList, appId, itemId, plateNo, checkCouponList } = this;
      let openId = localStorage.getItem("openId");
      let arrearList = [];
      let resultArray = [];
      let diffArray = [];
      if (this.selectList.length) {
        resultArray = checkCouponList.filter((item1) => this.selectList.some((item2) => item2.id === item1.parkId));
        diffArray = this.selectList.filter((item) => !resultArray.some((item1) => item1.parkId === item.id));
      }
      let useCouponList = [];
      let unUseCouponList = [];
      if (resultArray.length) {
        useCouponList = resultArray.map((item) => ({
          parkRecordId: item.parkId ? item.parkId + "" : item.id ? item.id + "" : null,
          code: item.parkId ? item.parkId : 0,
          deduction: item.parkId ? item.deduction : 0,
        }));
      }
      if (diffArray.length) {
        unUseCouponList = diffArray.map((item) => ({
          parkRecordId: item.parkId ? item.parkId + "" : item.id ? item.id + "" : null,
          code: 0,
          deduction: 0,
        }));
      }
      arrearList = useCouponList.concat(unUseCouponList);
      // if (diffArray.length) {
      //   arrearList = diffArray.concat(resultArray).map((item) => ({
      //     parkRecordId: item.parkId ? item.parkId + "" : item.id ? item.id + "" : null,
      //     code: item.parkId ? item.parkId : 0,
      //     deduction: item.parkId ? item.deduction : 0,
      //   }));
      // } else {
      //   arrearList = this.selectList.map((item) => ({
      //     parkRecordId: item.parkId ? item.parkId + "" : item.id ? item.id + "" : null,
      //     code: item.parkId ? item.parkId : 0,
      //     deduction: item.parkId ? item.deduction : 0,
      //   }));
      // }
      this.$axios
        .post(`${this.domain}/api/parklotPay/arrearPayOfParkLot`, {
          itemId,
          appId,
          plateNo,
          payMethod: "wxpay",
          openId,
          nos: recordList,
          arrearList: arrearList.length ? arrearList : null,
          payFee: this.arrearTotal + "",
        })
        .then((res) => {
          this.recordNoes = res.data.data.recordNoes;
          if (res.data.code == 200) {
            let data = res.data.data.data;
            this.weixinPay(data);
            this.paid = true;
          } else if (res.data.code == 404) {
            Dialog.alert({
              message: `${res.data.msg}`,
            }).then(() => {
              // on close
            });
          } else {
            Dialog.alert({
              message: `${res.data.msg}`,
            }).then(() => {
              // on close
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    // 微信收银台
    //解决微信内置对象报错
    weixinPay(data) {
      var that = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener("WeixinJSBridgeReady", that.onBridgeReady(data), false);
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", that.onBridgeReady(data));
          document.attachEvent("onWeixinJSBridgeReady", that.onBridgeReady(data));
        }
      } else {
        that.onBridgeReady(data);
      }
    },
    // 微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
    onBridgeReady(data) {
      WeixinJSBridge.invoke("getBrandWCPayRequest", data, (res) => {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          this.$router.go(-1);
        } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
          this.$axios
            .post(`${this.domain}/api/pay/cancelParkPay`, {
              recordNo: this.recordNoes.join(","),
            })
            .then((res) => {});
          alert("支付已取消，请重新扫码");
        } else {
          this.$router.push("/inpark/fail");
        }
      });
    },
    // 支付宝收银台
    // 由于js的载入是异步的，所以可以通过该方法，当AlipayJSBridgeReady事件发生后，再执行callback方法
    ready(callback) {
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        document.addEventListener("AlipayJSBridgeReady", callback, false);
      }
    },
    tradePay(tradeNO) {
      let that = this;
      this.ready(function () {
        // 通过传入交易号唤起快捷调用方式(注意tradeNO大小写严格)
        AlipayJSBridge.call(
          "tradePay",
          {
            tradeNO: tradeNO,
          },
          function (data) {
            if (data.resultCode === "9000") {
              that.$router.go(-1);
            } else if (data.resultCode === "6001") {
              this.$axios
                .post(`${this.domain}/api/pay/cancelParkPay`, {
                  recordNo: this.recordNoes.join(","),
                })
                .then((res) => {});
              alert("支付已取消，请重新扫码");
            } else {
              alert("支付失败，请重新扫码");
            }
          }
        );
      });
    },
    show() {
      this.showedit = !this.showedit;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}
.details {
  background-color: #f8f8f8;
  min-height: 100vh;

  .top {
    padding: 15px;
    padding-bottom: 55px;

    .topline {
      height: 20px;
      background-image: url("../../../../assets/topline.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .arrear-order {
      padding: 0 15px;
      padding-bottom: 0;
      background-color: #fff;

      .arrear-title {
        padding: 15px 0;
        font-size: 15px;
        font-weight: bold;
      }

      .arrear-box {
        .arrear-msg {
          margin-top: 5px;
          background-color: #f8f8f8;
          padding: 10px 15px;
          border-radius: 5px;
          line-height: 30px;

          .msg-box {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #a3a3a3;

            .value {
              color: #333333;
            }
          }
        }

        .check-coupon {
          display: flex;
          align-items: center;
          margin-top: 15px;

          .coupon {
            padding: 0;
          }
        }
      }

      .showall {
        padding-bottom: 10px;
        text-align: center;
        font-size: 14px;
        color: #a3a3a3;
      }
    }

    .foot-line {
      height: 20px;
      background-image: url("../../../../assets/footline.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .tab-bar {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    width: 100%;
    height: 50px;
    background-color: white;
    font-size: 14px;

    button {
      border: none;
      width: 100px;
      height: 50px;
      color: white;
    }
  }

  .coupon-popup {
    padding-bottom: 80px;

    .getcoupon-list {
      padding: 0 15px;

      .getcoupon-title {
        display: flex;
        justify-content: center;
        padding: 15px 0;

        img {
          width: 176px;
          height: 20px;
        }
      }

      .getcoupon-view {
        display: flex;
        margin-top: 10px;
        width: 100%;
        min-height: 90px;
        background: url("../../../../assets/getcoupon-listBG.png") no-repeat;
        background-size: 100% 100%;

        .left {
          box-sizing: border-box;
          padding: 10px 0 17px 17px;
          width: 80%;
          min-height: 90px;

          .getcoupon-view-top {
            color: #b45227;
            font-size: 16px;
            font-weight: 600;
          }

          .getcoupon-view-middle {
            font-size: 13px;
            color: #b45227;
          }

          .getcoupon-view-bottom {
            width: 100%;
            margin-top: 6px;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            .coupon-count {
              display: flex;
              height: 20px;
              border: 1px solid rgb(254, 174, 94);
              border-radius: 3px;
              font-size: 12px;

              .coupon-count-left {
                padding: 2px 5px;
                background: linear-gradient(90deg, rgb(250, 86, 45), rgb(254, 174, 94) 100%);
                color: #fefbca;
                white-space: nowrap;
              }

              .coupon-count-right {
                padding: 2px 5px;
                color: #ec8e31;
                white-space: nowrap;
              }
            }
          }
        }

        .right {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25%;
          min-height: 90px;

          .getbtn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: linear-gradient(135deg, rgb(255, 239, 216), rgb(255, 206, 114) 100%);
            border: 2px solid rgba(255, 118, 39, 0.5);
            font-size: 13px;
            color: #cd4104;
            font-weight: 600;
            text-align: center;
            white-space: pre-wrap;
          }
        }
      }
    }

    .gap {
      margin-top: 10px;
      width: 100%;
      height: 10px;
      background-color: #f8f8f8;
    }

    .coupon-list {
      padding: 0 15px;

      .coupon-title {
        display: flex;
        justify-content: center;
        padding-top: 15px;
        font-size: 18px;
      }

      .checkmsg {
        font-size: 15px;
        color: #333333;
      }

      .coupon-view {
        position: relative;
        box-sizing: border-box;
        margin-top: 10px;
        position: relative;
        width: 100%;
        min-height: 90px;
        border-radius: 10px;
        border: 0.5px solid rgba(255, 118, 39, 0.5);

        .expired-label {
          position: absolute;
          top: -4px;
          right: -4px;

          img {
            width: 60px;
            height: 34px;
          }
        }

        .semi-circle-top {
          position: absolute;
          top: -1px;
          left: 84px;
          width: 12px;
          height: 6px;
          background-color: #fff;
          border-radius: 0 0 10px 10px;
          border: 1px solid rgba(255, 118, 39, 0.5);
          border-top: 2px solid #fff;
        }

        .semi-circle-bottom {
          position: absolute;
          bottom: -1px;
          left: 84px;
          width: 12px;
          height: 6px;
          background-color: #fff;
          border-radius: 10px 10px 0 0;
          border: 1px solid rgba(255, 118, 39, 0.5);
          border-bottom: 2px solid #fff;
        }

        .coupon-type {
          position: absolute;
          top: -1px;
          left: -2px;
          width: 60px;
          height: 22px;
          line-height: 22px;
          background: url("../../../../assets/coupon-typeBG.png") no-repeat;
          color: #ff7627;
          padding-left: 9px;
        }

        .coupon-msg {
          display: flex;
          justify-content: space-between;
          padding: 7px;
          padding-left: 0;

          .coupon-favour-left {
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: center;
            margin-top: 8px;
            width: 90px;
            height: 60px;
            text-align: center;
            font-size: 12px;
            color: #ff7627;
            border-right: 1px dashed #ff7627;
          }

          .coupon-favour-right {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            padding: 5px 10px;
            width: 240px;
            height: 76px;
            border-radius: 8px;
            background: rgba(255, 118, 39, 0.05);

            .favour-dsc {
              width: 90%;
              color: #333333;
              font-size: 15px;
              font-weight: 600;
            }

            .select {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 10px;
            }

            .favour-date {
              display: flex;
              justify-content: space-between;
              color: #a3a3a3;
              font-size: 12px;
            }
          }
        }
      }
    }

    .coupon-define {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      gap: 10px;
      padding: 15px;
      background-color: #fff;
      box-shadow: 0px -2px 25px 0px rgba(0, 0, 0, 0.05);

      .cancel {
        flex: 0.5;
        background: rgba(255, 118, 39, 0.1);
        color: #ff7627;
        border: none;
      }

      .submit {
        flex: 1;
        background: linear-gradient(180deg, rgb(255, 167, 99), rgb(250, 114, 12) 100%);
        color: #ffffff;
        border: none;
      }
    }
  }
}

::v-deep .van-cell__value {
  color: #eb5757;
  font-size: 15px;
  font-weight: 400;
}

::v-deep .van-cell__right-icon {
  color: #eb5757;
  font-size: 15px;
  font-weight: 400;
}

::v-deep .van-tag--plain {
  position: absolute;
  top: 50%;
  transform: translate(0, -42%);
  background-color: #fff;
  margin-left: 5px;
  border-radius: 5px;
  color: #eb5757;
}

::v-deep .van-checkbox {
  width: 25px;
}

.active {
  border: 2px solid #ff7627 !important;
}

.active-topround {
  border: 2px solid #ff7627 !important;
  border-top: 3px solid #fff !important;
  top: -2.5px !important;
}

.active-bottomround {
  border: 2px solid #ff7627 !important;
  border-bottom: 3px solid #fff !important;
  bottom: -2.5px !important;
}
</style>
